import React, { useState } from "react";
import { FaPlay } from "react-icons/fa"; // Import the play icon
import Contact from "../sections/Contact";
import BackToHome from "../components/BackToHome";
import Footer from "../sections/Footer";

function FishGame() {
  const [isGameStarted, setIsGameStarted] = useState(false); // Track whether the game has started

  const startGame = () => {
    setIsGameStarted(true); // Start the game
  };

  return (
    <>
      <BackToHome />
      <div
        style={{
          backgroundColor: "#FFF8E8",
          width: "100%",
          padding: "100px 20px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          boxShadow: "0px 10px 15px rgba(0, 0, 0, 0.3)",
          position: "relative",
          zIndex: "11",
        }}
      >
        <h1
          style={{
            marginBottom: "20px",
            color: "#FF204E",
            fontSize: "3rem",
            fontWeight: "bold",
            textAlign: "center",
            width: "60%",
          }}
        >
          Eat Fish
        </h1>

        <div
          style={{
            width: "60%",
            height: "4px",
            backgroundColor: "#FF204E",
            marginBottom: "30px",
          }}
        />

        {!isGameStarted ? (
          // Make the placeholder image and the button clickable
          <div
            onClick={startGame} // Handle click anywhere on the placeholder
            style={{
              position: "relative",
              width: "60%",
              height: "auto",
              cursor: "pointer",
            }}
          >
            <img
              src="/fishGame/CoverFishGame.png"
              alt="Fish Game Preview"
              style={{
                width: "100%",
                height: "800px", // Match the iframe height
                objectFit: "cover", // Ensures it fills the container properly
                borderRadius: "8px",
                boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
              }}
            />
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                backgroundColor: "rgba(255, 32, 78, 0.8)",
                color: "white",
                borderRadius: "50%",
                width: "80px",
                height: "80px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                boxShadow: "0 4px 10px rgba(0, 0, 0, 0.3)",
                pointerEvents: "none", // Prevent the button itself from blocking the click
              }}
            >
              <FaPlay size={36} /> {/* Play icon */}
            </div>
          </div>
        ) : (
          <iframe
            src="/fishGame/fish_game.html"
            style={{
              width: "60%",
              height: "800px",
              border: "none",
            }}
            title="Fish Game"
          ></iframe>
        )}
        <p
          style={{
            color: "#333",
            width: "45%",
            lineHeight: "1.6",
            marginBottom: "30px",
            marginTop: "50px",
            fontSize: "28px",
          }}
        >
          <strong>"Eat Fish"</strong> is an HTML5 game I developed using the
          Godot Engine in collaboration with the talented artist{" "}
          <a href="https://www.instagram.com/menacinglobster/">
            @menacingLobster
          </a>
          . In this game, you play as a fish that can eat smaller fish to grow
          in size, but beware—larger fish can kill you! The game offers an
          engaging and dynamic experience, featuring:
          <ul>
            <li>Infinitely spawning enemies</li>
            <li>Fully functional Main, Pause, and Death menus</li>
            <li>Multiplayer support for up to 3 players</li>
          </ul>
        </p>
      </div>
      <Contact />
      <Footer />
    </>
  );
}

export default FishGame;
