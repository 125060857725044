import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import MainPage from "./pages/MainPage";
import NubedianWork from "./pages/NubedianWork";
import MTLSWork from "./pages/MTLSWork";
import ChatBotWork from "./pages/ChatBotWork";
import { LoadingProvider } from "./LoadingContext";
import GlobalLoadingScreen from "./components/GlobalLoadingScreen";
import FishGame from "./pages/FishGame";

function App() {
  return (
    <LoadingProvider>
      <GlobalLoadingScreen />
      <Router>
        <div>
          <Routes>
            <Route path="/" element={<MainPage />} />
            <Route path="/nubedian" element={<NubedianWork />} />
            <Route path="/mtls" element={<MTLSWork />} />
            <Route path="/creativeCode" element={<ChatBotWork />} />
            <Route path="/fishGame" element={<FishGame />} />
          </Routes>
        </div>
      </Router>
    </LoadingProvider>
  );
}

export default App;
