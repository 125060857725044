function SampleCard({
  image,
  title,
  description,
  source,
  demo,
  isGif,
  inDomain,
}) {
  // Helper function to navigate internally
  const handleNavigation = (url) => {
    if (url) window.location.href = url;
  };

  return (
    <div
      style={{
        backgroundColor: "#ffffff",
        width: "390px",
        padding: "20px",
        borderRadius: "10px",
        boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.1)",
        textAlign: "center",
        margin: "10px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <img
        src={image}
        alt={title}
        style={{
          width: "100%",
          height: "190px",
          objectFit: isGif ? "contain" : "cover",
          borderRadius: "10px",
        }}
      />

      {title && (
        <h3
          style={{
            margin: "15px 0 10px",
            fontSize: "1.5rem",
            color: "#333",
          }}
        >
          {title}
        </h3>
      )}

      {description && (
        <p
          style={{
            margin: "0 0 15px",
            fontSize: "16px",
            color: "#666",
            lineHeight: "1.5",
          }}
        >
          {description}
        </p>
      )}

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "10px",
          flexGrow: 1,
        }}
      >
        {demo && (
          <button
            onClick={() => handleNavigation(demo)}
            style={{
              background: "none",
              border: "none",
              color: "#FF204E",
              fontWeight: "bold",
              cursor: "pointer",
            }}
          >
            Demo
          </button>
        )}

        {source && (
          <button
            onClick={() => handleNavigation(source)}
            style={{
              background: "none",
              border: "none",
              color: "#FF204E",
              fontWeight: "bold",
              cursor: "pointer",
              textAlign: "right",
            }}
          >
            Source Code
          </button>
        )}
      </div>
    </div>
  );
}

export default SampleCard;
