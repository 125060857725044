import React, { useContext } from "react";
import { useLoading } from "../LoadingContext";

const GlobalLoadingScreen = () => {
  const { isLoading } = useLoading();

  if (!isLoading) return null;

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(255, 255, 255, 0.8)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1000,
      }}
    >
      <div
        style={{
          border: "16px solid #f3f3f3",
          borderTop: "16px solid #3498db",
          borderRadius: "50%",
          width: "80px",
          height: "80px",
          animation: "spin 2s linear infinite",
        }}
      />
    </div>
  );
};

export default GlobalLoadingScreen;
