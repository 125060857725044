import React, { createContext, useState, useContext } from "react";

const LoadingContext = createContext();

export const LoadingProvider = ({ children }) => {
  const [loadingCount, setLoadingCount] = useState(0);

  const addLoading = () => setLoadingCount((count) => count + 1);
  const removeLoading = () =>
    setLoadingCount((count) => Math.max(count - 1, 0));

  return (
    <LoadingContext.Provider
      value={{
        isLoading: loadingCount > 0,
        addLoading,
        removeLoading,
      }}
    >
      {children}
    </LoadingContext.Provider>
  );
};

export const useLoading = () => useContext(LoadingContext);
